.toolBar {
  background-color: #f9fafb;
  height: 8vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button-content {
  display: flex;
  justify-content: end;
  align-items: center;
}
.action {
  margin-left: 5px;
}

.buttonCratePosition {
  margin-right: 10px;
}
