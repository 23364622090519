.custom-file-label::after {
  content: "Browse";
}

.custom-file .custom-file-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-group-append .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
