.close-btn {
  cursor: pointer;
  transform: rotate(45deg);
}
.button-content-add-brand {
  width: 3vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.5s ease;
}
.button-content-add-brand:hover {
  background-color: rgb(235, 238, 238);
}

.body .btn-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1vh;
}
