.items-list {
  font-size: 1rem;
  color: #334155;
  height: 92vh;
}

.details-container {
  margin-left: 70px;
}

.details-container .section {
  margin-left: 20px;
}

.margin-styles {
  margin-bottom: 1vh;
}

.action-button {
  width: 10vw;
  height: 4vh;
}
