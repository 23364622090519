.section .form-image {
	display: flex;
	flex-direction: column;
	height: 20%;
	width: 20%;
	margin: 0 7% 0 5%;
  
	--b: .5em; /* border width */
	--c: 3em; /* corner size */
	--r: 2em; /* corner rounding */
	position: relative;
	margin: 1em auto;
	border: solid var(--b) transparent;
	padding: 1%;
	max-width: 23em;
	font: 1.25em ubuntu, sans-serif;
	
	&::before {
	  position: absolute;
	  z-index: -1;
	  inset: calc(-1*var(--b));
	  border: inherit;
	  border-radius: var(--r);
	  background: linear-gradient(rgb(233, 214, 179), rgb(236, 188, 214), rgb(223, 177, 223)) border-box;
	  --corner: 
		conic-gradient(from -90deg at var(--c) var(--c), red 25%, #0000 0) 
		  0 0/ calc(100% - var(--c))  calc(100% - var(--c)) border-box;
	  --inner: conic-gradient(red 0 0) padding-box;
	  -webkit-mask: var(--corner), var(--inner);
	  -webkit-mask-composite: source-out;
	  mask: var(--corner) subtract, var(--inner);
	  mask-composite: subtract; /* Add this line */
	  content: '';
	}
  }
  .input-button-wrapper {
	display: flex;
	align-items: center; /* Align items vertically centered */
  }
  
  .text-field-styles {
	flex: 1; /* Allow the text field to take available space */
  }
  
  .button-styles {
	flex: 1; /* Ensure button takes the same space as text field */
	height: 100%; /* Make button height same as text field */
	margin-left: 10px; /* Add space between text field and button */
  }

  .center-button-wrapper {
	margin: 3%;
	display: flex;
	justify-content: center; /* Center the button horizontally */
  }

  .section .form-container {
	display: flex;
	gap: 1vw;
	justify-content: flex-start;
	align-items: center;
  }
  
  .section .button-content-container {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  gap: 0.5vw;
	}
	
	.row-elements-container{
	  display: flex;
	  justify-content: space-between;
	  align-items: flex-start;
	  gap: 10px;
	}
	
	.small-margin {
	  margin: 3% 0;
	}
  