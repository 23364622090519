.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
  pointer-events: fill;
}

.modal__content {
  padding: 20px;
  border-radius: 12px;
  background-color: white;
}
