.body .btn-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1vh;
}
.button-content-address-book {
  width: 3vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.5s ease;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-content-address-book:hover {
  background-color: rgb(235, 238, 238);
}

.close-btn {
  cursor: pointer;
  transform: rotate(45deg);
}

.body .text-field-styles {
  width: 15vw;
}

.body .form-container {
  display: flex;
  gap: 1vw;
  justify-content: flex-start;
}
