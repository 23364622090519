.body .form-container {
  display: flex;
  gap: 1vw;
  justify-content: flex-start;
  margin-top: 10px;
  align-items: center;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close-btn {
  cursor: pointer;
  transform: rotate(45deg);
}
