.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: auto;
}
.wrapper {
  width: 30%;
}

.form-control::placeholder {
  color: #bcbec0;
  font-size: 14;
}
