.details-container .section {
  margin-left: 20px;
}

.details-container .section .form {
  margin-left: 12px;
}

.details-container .section .form .text-field-styles {
  width: 19.5vw;
}

.details-container .section .form .custom-table-styles {
  width: 70vw;
}

.details-container .section .form .form-container {
  display: flex;
  gap: 1vw;
  justify-content: flex-start;
  align-items: center;
}

.actions {
  display: flex;
}

.actions-buttons {
  margin-left: 56vw;
  margin-bottom: 1vh;
}

.cancel-btn {
  height: 4vh;
  background-color: #c0c0c0;
  border: none;
}

.cancel-btn:hover {
  background-color: #d6d6d6;
}
