.section .form-container {
  display: flex;
  gap: 1vw;
  justify-content: flex-start;
  align-items: center;
}

.section .button-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
}
